import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from 'prop-types';

const PersonProfileImage = ({ src, ...imgAttr }) => {
  // sourceInstanceName defined in gatsby-config
  const data = useStaticQuery(graphql`
    query {
      peopleimages: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "bixal-people" }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                width: 600
                height: 600
                placeholder: NONE,
                transformOptions: { grayscale: true, cropFocus: CENTER }
              )
            }
          }
        }
      }
      defaultImage: file(
        relativePath: { eq: "bixal-people/default-team-bio-photo.jpg" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            width: 600
            height: 600
            transformOptions: { grayscale: true, cropFocus: CENTER }
          )
          original {
            src
          }
        }
      }
    }
  `)

  const findImage = useMemo(
    () => data.peopleimages.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  )

  if (!findImage) return <GatsbyImage
      loading="lazy"
      image={data.defaultImage.childImageSharp.gatsbyImageData}
      style={{ width: '100%', height: '100%'}}
      {...imgAttr}
    />

  let { node: { childImageSharp } = {} } = findImage

  return <GatsbyImage
      loading="lazy"
      image={childImageSharp.gatsbyImageData}
      style={{ width: '100%', height: '100%'}}
      {...imgAttr}
    />
}

PersonProfileImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

export default PersonProfileImage;
