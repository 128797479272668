import React from "react"
import PersonProfileImage from "../components/PersonProfileImage"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { graphql } from "gatsby"
import Social from "../components/SocialNav"
import ReturnToSiteLink from "../components/ReturnToSiteLink"

export default function CaseStudyTemplate({ data }) {
  const peopleData = data.bixalPeopleJson

  return (
    <Layout id={peopleData.jsonId}>
      <Seo
        title={peopleData.name}
        description={peopleData.name}
        relPath={`/about/${peopleData.jsonId}/`}
      />
      <div className="person-profile">
        <div className="person-profile__main">
          <div className="person-profile__main-container">
            <div className="person-profile__intro">
              <div className="person-profile__image">
                <div className="person-profile__image-placeholder">
                  <div className="person-profile__image-positioner">
                    <PersonProfileImage
                      src={`bixal-people/${peopleData.jsonId}.jpg`}
                      alt={peopleData.name}
                    />
                  </div>
                </div>
              </div>

              <div className="person-profile__description">
                <h1 className="person-profile__title">{peopleData.name}</h1>

                <div className="person-profile__job-description">
                  <div className="person-profile__role">
                    {peopleData.jobTitle}
                  </div>
                </div>

                <hr className="person-profile__decorative-divider" />

                {
                  peopleData.linkedin ? (
                    <Social
                      networks={[
                        {
                          type: 'linkedIn',
                          url: peopleData.linkedin,
                        }
                      ]}
                    />
                  ) : null}
              </div>
            </div>
            {/* 
            <div className="person-profile__bio">
              {peopleData.bioParagraph.split('\n').map(p => {
                return <p key={p}>{p}</p>
              })}
            </div> */}
          </div>
        </div>

        <div className="person-profile__aside">
          <div className="person-profile__aside-container">
            {peopleData.specialties ||
              peopleData.languages ||
              peopleData.bucketList ? (
              <div className="person-profile__interests">
                {peopleData.specialties && (
                  <div className="person-profile__specialties">
                    <h2>Specialties</h2>
                    <ul>
                      {peopleData.specialties.split('\n').map(s => {
                        return <li key={s}>{s}</li>
                      })}
                    </ul>
                  </div>
                )}

                {peopleData.certifications && (
                  <div className="person-profile__certifications">
                    <h2>Certifications</h2>
                    <ul>
                      {peopleData.certifications.split('\n').map(c => {
                        return <li key={c}>{c}</li>
                      })}
                    </ul>
                  </div>
                )}

                {peopleData.languages && (
                  <div className="person-profile__languages">
                    <h2>Languages</h2>
                    <ul>
                      {peopleData.languages.split('\n').map(l => {
                        return <li key={l}>{l}</li>
                      })}
                    </ul>
                  </div>
                )}

                {peopleData.bucketList && (
                  <div className="person-profile__bucket-list">
                    <h2>Bucket List</h2>
                    <p className="profile-bucketlist">
                      {peopleData.bucketList}
                    </p>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="person-profile__back-link">
        <ReturnToSiteLink
          location="/about/#team-members"
          position={'bottom'}
          value={'Back to Team Bixal'}
        ></ReturnToSiteLink>
      </div>
    </Layout>
  )
}

export const query = graphql`
query PeopleTemplate($jsonId: String!) {
  bixalPeopleJson(jsonId: { eq: $jsonId }) {
    awards
    bioParagraph
    bucketList
    certifications
    department
    github
    jsonId
    image
    jobTitle
    languages
    linkedin
    medium
    name
    specialties
    twitter
    misc
    }
  }
`
